function fetchNameFavoriteCount(slug) {
  $.ajax({
    url: "/names/" + slug + "/favorite_count",
    method: 'GET'
  }).done(function(data) {
    $('.favorite-count-fetcher[data-slug=' + slug + ']').text(data.count)
  })
}

$(document).on('click', '.action.name-mark-favorite', function() {
  $.ajax({
    url: "/names/" + $(this).data('slug') + "/mark_favorite",
    method: 'POST',
    context: $(this)
  }).done(function() {
    $(this).removeClass('name-mark-favorite')
    $(this).addClass('name-remove-favorite')
    $(this).attr('data-original-title', 'Hapus dari favorit')
    $('.tooltip').remove()
  }).fail(function( jqXHR, textStatus ) {
    switch (jqXHR.status) {
      case 422:
        $(this).removeClass('name-mark-favorite')
        $(this).addClass('name-remove-favorite')
        $(this).attr('data-original-title', 'Hapus dari favorit')
        $('.tooltip').remove()
        break;
      case 401:
        $('#modal_login').modal('show');
        break;
      case 403:
        swal({
          text: JSON.parse(jqXHR.responseText).error,
          icon: 'error',
          button: 'Close'
        });
        break;
      default:
        swal({
          title: 'Error',
          text: 'Tidak dapat diproses',
          icon: 'error',
          button: 'Close'
        });
    }
  })
})

$(document).on('click', '.action.name-remove-favorite', function() {
  $.ajax({
    url: "/names/" + $(this).data('slug') + "/remove_favorite",
    method: 'POST',
    context: $(this)
  }).done(function() {
    $(this).removeClass('name-remove-favorite')
    $(this).addClass('name-mark-favorite')
    $(this).attr('data-original-title', 'Tambahkan ke favorit')
    $('.tooltip').remove()
    if ($('.nmn-table-favorite').length) {
      $(this).parents('tr').fadeOut(function() {
        $(this).remove()
      })
    }
  }).fail(function( jqXHR, textStatus ) {
    switch (jqXHR.status) {
      case 401:
        $('#modal_login').modal('show');
        break;
      default:
        swal({
          title: 'Error',
          text: 'Tidak dapat diproses',
          icon: 'error',
          button: 'Close'
        });
    }
  })
})

// Single name add favorite
$(document).on('click', '.name-detail-action .name-mark-favorite', function() {
  $.ajax({
    url: "/names/" + $(this).data('slug') + "/mark_favorite",
    method: 'POST',
    context: $(this)
  }).done(function() {
    $(this).removeClass('name-mark-favorite')
    $(this).removeClass('btn-primary')
    $(this).addClass('name-remove-favorite')
    $(this).addClass('btn-dark')
    $(this).text('Hapus dari favorit')
    fetchNameFavoriteCount($(this).data('slug'))
  }).fail(function( jqXHR, textStatus ) {
    switch (jqXHR.status) {
      case 422:
        swal({
          text: 'Sudah difavoritkan',
          icon: 'warning',
          button: 'Close'
        });
        break;
      case 401:
        $('#modal_login').modal('show')
        break;
      case 403:
        swal({
          text: JSON.parse(jqXHR.responseText).error,
          icon: 'error',
          button: 'Close'
        });
        break;
      default:
        swal({
          title: 'Error',
          text: 'Tidak dapat diproses',
          icon: 'error',
          button: 'Close'
        });
    }
  })
})

// Single name remove favorite
$(document).on('click', '.name-detail-action .name-remove-favorite', function() {
  $.ajax({
    url: "/names/" + $(this).data('slug') + "/remove_favorite",
    method: 'POST',
    context: $(this)
  }).done(function() {
    $(this).removeClass('name-remove-favorite')
    $(this).removeClass('btn-dark')
    $(this).addClass('name-mark-favorite')
    $(this).addClass('btn-primary')
    $(this).text('+ Tambahkan ke favorit')
    fetchNameFavoriteCount($(this).data('slug'))
  }).fail(function( jqXHR, textStatus ) {
    switch (jqXHR.status) {
      case 401:
        $('#modal_login').modal('show');
        break;
      default:
        swal({
          title: 'Error',
          text: 'Tidak dapat diproses',
          icon: 'error',
          button: 'Close'
        });
    }
  })
})

$(document).on('click', '.action.vote-name', function() {
  $.ajax({
    url: "/votes/" + $(this).data('name-list-id') + "/vote_name",
    method: 'POST',
    data: {
      option_id: $(this).data('full-name-option-id'),
      value: $(this).data('value')
    },
    context: $(this)
  }).done(function() {
    $(this).siblings('.vote-selection').remove()
    $(this).removeClass('action vote-name')
    swal({
      text: 'Vote sudah diberikan',
      icon: 'success',
      button: 'OK'
    })
  }).fail(function( jqXHR, textStatus ) {
    switch (jqXHR.status) {
      case 422:
        swal({
          text: 'Sudah divote',
          icon: 'warning',
          button: 'Close'
        });
        break;
      case 401:
        $('#modal_login').modal('show');
        break;
      default:
        swal({
          title: 'Error',
          text: 'Tidak dapat diproses',
          icon: 'error',
          button: 'Close'
        });
    }
  })
})

$(document).on('cocoon:before-insert', '#full_name_options', function(e, insertedItem, originalEvent) {
  if (($('#full_name_options .nested-fields').length) > 29) {
    swal({
      text: 'Maksimal 30 nama',
      icon: 'warning',
      button: 'Close'
    });
    e.preventDefault();
  }

})

$(document).on('cocoon:after-insert', '#full_name_options', function(e, addedItem) {
  $(addedItem).find('.fullname-control').selectize({
    plugins: ['remove_button'],
    delimiter: ',',
    persist: false,
    create: function(input) {
      return {
        value: input,
        text: input
      }
    }
  })
})

$(document).on('click', '.copy-link', function() {
  var $temp = $('<input>');
  $('body').append($temp);
  $temp.val($('#linkShare').text()).select();
  document.execCommand('copy');
  $temp.remove();
  $(this).attr('data-original-title', 'Link ter-copy')
  $(this).parents('body').find('.tooltip-inner').text('Link ter-copy')
  $(this).parents('body').find('.copy-link').attr('data-original-title', 'Copy link')
})

dataConfirmModal.setDefaults({
  title: 'Apakah anda yakin',
  commit: 'Ya',
  cancel: 'Batal',
  modalClass: 'modal-confirm'
})

setTimeout(function() {
  $('.nmn-notice .alert').alert('close')
}, 5000)


$(function() {

  var REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)'
  var winWidth = $(window).width();
  var winHeight = $(window).height();

  $('.nmn-menu-toggle').click(function() {
    $(this).parent().toggleClass('menu-open');
    $(this).parent().removeClass('cog-open');
    if ($(this).parent().hasClass('menu-open')) {
      $('body').addClass('toggled');
    } else {
      $('body').removeClass('toggled');
    }
  });
  $('.nmn-cog-toggle').click(function() {
    $(this).parent().toggleClass('cog-open');
    $(this).parent().removeClass('menu-open');
    if ($(this).parent().hasClass('cog-open')) {
      $('body').addClass('toggled');
    } else {
      $('body').removeClass('toggled');
    }
  });

  $('.selectize').selectize();
  $('.fullname-control').selectize({
    plugins: ['remove_button'],
    delimiter: ',',
    persist: false,
    create: function(input) {
      return {
        value: input,
        text: input
      }
    }
  })
  $('.invite-email-form').selectize({
    plugins: ['remove_button'],
    delimiter: ',',
    persist: false,
    valueField: 'email',
    labelField: 'name',
    render: {
      item: function(item, escape) {
          return '<div>' +
              (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '') +
              (item.email ? '<span class="email">' + escape(item.email) + '</span>' : '') +
          '</div>';
      },
      option: function(item, escape) {
          var label = item.name || item.email;
          var caption = item.name ? item.email : null;
          return '<div>' +
              '<span class="label">' + escape(label) + '</span>' +
              (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
          '</div>';
      }
    },
    createFilter: function(input) {
        var match, regex;

        // email@address.com
        regex = new RegExp('^' + REGEX_EMAIL + '$', 'i');
        match = input.match(regex);
        if (match) return !this.options.hasOwnProperty(match[0]);

        // name <email@address.com>
        regex = new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i');
        match = input.match(regex);
        if (match) return !this.options.hasOwnProperty(match[2]);

        return false;
    },
    create: function(input) {
      if ((new RegExp('^' + REGEX_EMAIL + '$', 'i')).test(input)) {
        return {
          email: input
        };
      }
      var match = input.match(new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i'));
      if (match) {
        return {
          email : match[2],
          name  : $.trim(match[1])
        };
      }
      alert('Invalid email address.');
      return false;
    }
  })
  $('[data-toggle="tooltip"]').tooltip({
    container: 'body'
  });

  $('#new_user').validate({
    rules: {
      'user[first_name]': {
        required: true,
      },
      'user[email]': {
        required: true,
        email: true
      },
      'user[password]': {
        required: true,
      },
      'user[password_confirmation]': {
        required: true,
        equalTo: '#user_password'
      }
    }
  })

  if ($('.nmn-table-result').length) {
    var tableOffset = $('.nmn-table-result').offset().top;
    // $('html, body').animate({ scrollTop: tableOffset - 100 });
  }

  $('.favorite-count-fetcher').each(function() {
    fetchNameFavoriteCount($(this).data('slug'))
  });

  if (winHeight > 667 && winHeight <= 812) {
    $('body').addClass('mh');
  }

});