// jQuery validation
$.extend( $.validator.messages, {
  required: "Wajib diisi",
  remote: "Harap perbaiki kolom ini",
  email: "Silakan masukkan format email yang benar",
  url: "Silakan masukkan format URL yang benar",
  date: "Silakan masukkan format tanggal yang benar",
  dateISO: "Silakan masukkan format tanggal (ISO) yang benar",
  number: "Harap masukkan angka saja",
  digits: "Harap masukkan angka saja",
  creditcard: "Harap masukkan format kartu kredit yang benar",
  equalTo: "Tidak sesuai",
  maxlength: $.validator.format( "Maksimal {0} karakter" ),
  minlength: $.validator.format( "Minimal {0} karakter" ),
  rangelength: $.validator.format( "Panjang karakter yg diperbolehkan antara {0} dan {1} karakter" ),
  range: $.validator.format( "Nilai yang diperbolehkan antara {0} dan {1}" ),
  max: $.validator.format( "Nilai harus lebih kecil atau sama dengan {0}" ),
  min: $.validator.format( "Nilai harus lebih besar atau sama dengan {0}" ),
  step: $.validator.format( "Harap masukkan kelipatan {0}" )
} );